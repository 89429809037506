.signUpForm{
  display: flex;
  justify-content: center;
}

.signUpHeader{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 140px; */
  padding: 40px 0px 14px 0px;
}

.signUpHeader .headline{
  font-weight: 600;
  font-size: 18px;
}

.signUpForm input{
  height: 24px;
}

.signUpForm .ant-input-prefix{
  margin-right: 12px;
}

.signUpForm .ant-input:hover{
  background-color: #EEF0F4;
}

.signUpForm .ant-form-item-control-input-content input{
  background-color: #EEF0F4;
}

.signUpForm .ant-form-item-control{
  border-radius: 16px !important;
}

.signUpForm .ant-form-item-control-input{
  border-radius: 16px !important;
}

.signUpForm .ant-form-item-control-input-content{
  border-radius: 16px !important;
}

.signUpForm .login-form-button{
  width: 100%;
  background-color: #08BD80;
  border-radius: 8px;
  height: 36px;
  /* padding: 3px 0px 7px 0px; */
}

.signUpForm .ant-form-item-explain-error{
  font-size: 11px;
  /* text-align: end; */
}

.signUpForm .login-form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* height: 58vh; */
  height: 300px;
  width: 42%;
  min-width: 300px;
}

.signUpForm .formFooter{
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}

.signUpForm .formFooter a{
  color: #08BD80;
  font-weight: 1000;
  margin-left: 4.5px;
}

.bg{
  background: url('../../assets/background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
}



