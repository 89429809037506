.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea .heading{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
}

.displayArea .promoteBlock{
  padding: 24px;
  background-color: #FFFFFF;
  /* box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5); */
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayArea .promoteBlock .dataLabel{
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 7px;
}

.displayArea .promoteBlock .choiceArea{
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.displayArea .promoteBlock .choice{
  flex-grow: 1;
}

.displayArea .promoteBlock .footer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  width: 93.4%;
}

.displayArea .promoteBlock .footer .cancelButton{
  margin-right: 30px;
  height: 36px;
  width: 160px;
}

.displayArea .promoteBlock .footer .addCourseButton {
  width: 160px;
  background-color: #08bd80;
  border-radius: 8px;
  height: 36px;
}

/* CSS for recent-promotions-table */
.displayArea .recentPromotionsTableBody {
  margin-top: 30px;
  background-color: #ffffff;
  padding-top: 26px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
}

.displayArea .recentPromotionsTableBody .tableHeader{
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 16px;
}

.displayArea .recentPromotionsTableBody .tableHeader img{
  width: 16.8px;
  opacity: 0.5;
}

.displayArea .recentPromotionsTableBody .tableHeader h6{
  line-height: 90%;
  margin-bottom: 0px;
  font-size: 14px;
  margin-left: 10px;
  opacity: 0.5;
}