.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea h6{
  margin-bottom: 0px;
  line-height: normal;
  line-height: 100%;
}

.displayArea .heading{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}

.displayArea .subHeading{
  opacity: 0.7;
  font-size: 12.9px;
  margin-bottom: 30px;
}

.displayArea .accountData{
  background-color: #FFFFFF;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */
  /* box-shadow:
  -7px -7px 20px 0px #fff9,
  -4px -4px 5px 0px #fff9,
  7px 7px 20px 0px #0002,
  4px 4px 5px 0px #0001,
  inset 0px 0px 0px 0px #fff9,
  inset 0px 0px 0px 0px #0001,
  inset 0px 0px 0px 0px #fff9,inset 0px 0px 0px 0px #0001; */
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border-radius: 12px;
  padding: 20px 40px;
  margin-bottom: 30px;
  min-height: 200px;
  /* border-left: 7px #1AB273 solid; */
}

.displayArea .accountData .dataLabel{
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 18px;
}

.displayArea .accountData .accountsDisplay{
  display: flex;
  flex-wrap: wrap;
}

.displayArea .accountData .accountsDisplay .addUser{
  background-color: #FFFFFF;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 264px;
  height: 84.4px;
  border-radius: 12px;
  border: 2px dashed #1AB373;
  cursor: pointer;
  margin-right: 20px;
}

.displayArea .accountData .accountsDisplay .addUser span{
  color: #1AB373;
  font-weight: 600;
  font-size: 16px;
  margin-right: 12px;
}

.displayArea .accountData .accountsDisplay .addUser img{
  height: 18px;
  transform: translateY(2px);
}

.displayArea .accountData .userBlock{
  display: flex;
  align-items: center;
  border: #ccc 1.2px solid;
  border-radius: 12px;
  padding: 16px;
  margin-right: 24px;
  margin-bottom: 20px;
}

.displayArea .accountData .userBlock .userIcon{
  box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
  padding: 5px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
}

.displayArea .accountData .userBlock .userData{
  margin-right: 14px;
}

.displayArea .accountData .userBlock .userData .userName{
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 650;
}

.displayArea .accountData .userBlock .userData .userDesig{
  font-size: 12px;
  opacity: 0.8;
  margin-bottom: 1.4px;
}

.displayArea .accountData .userBlock .userData .userEmail{
  font-size: 11px;
  opacity: 0.6;
}

.displayArea .accountData .userBlock .arrowIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.displayArea .accountData .userBlock .arrowIcon:hover{
  background-color: #F9F9F9;
}




/* INSTITUTION BLOCK */
.displayArea .institutionData{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 28px 40px;
  /* box-shadow:
  -7px -7px 20px 0px #fff9,
  -4px -4px 5px 0px #fff9,
  7px 7px 20px 0px #0002,
  4px 4px 5px 0px #0001,
  inset 0px 0px 0px 0px #fff9,
  inset 0px 0px 0px 0px #0001,
  inset 0px 0px 0px 0px #fff9,inset 0px 0px 0px 0px #0001; */
  /* transition:box-shadow 0.6s cubic-bezier(.79,.21,.06,.81); */
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
}

.displayArea .institutionData .dataLabel{
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 7px;
}

.displayArea .institutionData .dataValue{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

.displayArea .institutionData .bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .institutionData .bottom .changePass,.Resetpassword{
  display: flex;
}

.displayArea .institutionData .bottom .changePass img,.Resetpassword img{
  margin-right: 12px;
}
.Resetpassword img{
  width:20px;
  height:20px;
}

.displayArea .institutionData .bottom .changePass h6,.Resetpassword h6{
  font-size: 14px;
  color:#1AB273;
  color: #290CE9;
  font-weight: 600;
  transform: translateY(2.9px);
  cursor: pointer;
}


.editDetails{
  padding: 12px 30px;
  border: 2px dashed #09DCA8;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  font-weight: 500;
  text-align:center;
  
}

.editDetails:hover{
  background-color: #edf0f585;
  border: 2px dashed #05f1b6;
}


.password{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}
.passwordInput{
  margin-right:2rem !important;
  margin-left:2rem !important;
  max-width:100vw !important;
  margin-bottom: 30px !important;
}
.passwordLabel{
  margin-bottom:1rem;
  font-size:0.8rem;
  color:grey;
  align-self:flex-start;
}
.firstRow{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.setting{
  background:rgba(255, 0, 0, 0.158);
  padding:8px;
  border-radius:4px;
  margin-bottom:24px;
  border:1.5px solid rgba(255, 0, 0, 0.589);
}
