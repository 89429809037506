*{
  margin: 0;
}

.SideBar{
  width: 220px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  z-index: 100;
}

.SideBar::-webkit-scrollbar{
  display: none;
}

.SideBar .SidebarHeader{
  background-color: #1AB273;
  background-color: #09DCA8;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.SideBar .SidebarHeader img{
  height: 32px;
  width: 32px;
  margin-right: 12px;
}

.SideBar .SidebarHeader h6{
  font-weight: 600;
  font-size: 18px;
  color: white;
  margin-bottom: 4px;
}