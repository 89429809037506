.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
}

.displayArea h6{
  margin-bottom: 0px;
  line-height: normal;
}



/* CSS for course-branch-selection area */

.displayArea .selectionContainer{
  padding: 24px;
  background-color: #FFFFFF;
  /* box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5); */
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.displayArea .selectionContainer .dataLabel{
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 7px;
}

.displayArea .selectionContainer .choiceArea{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  /* width: 90%; */
}

.displayArea .selectionContainer .choice{
  flex-grow: 1;
}


/* CSS for search-bar */
.displayArea .feeSearchForm{
  width: 30%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: #ccc 1.2px solid;
  /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05); */
  border-radius: 4px;
  margin-right: 40px;
}
.displayArea .feeSearchForm input{
  width: 100%;
  padding-bottom: 2px;
  padding-left: 16.2px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
}


/* CSS for table */
.displayArea .feeStatusTableBody{
  background-color: #FFFFFF;
  padding-top: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
}

.displayArea .feeStatusTableBody .tableTop{
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .feeStatusTableBody .tableTop .tableTopHeading{
  font-size: 16px;
  margin-right: 20px;
}

.displayArea .feeStatusTableBody .tableTop .visOption{
  margin: 4px 0px !important;
}


