.field {
  border: 1.2px solid #ccc;
  border-radius: 8px;
  padding: 19px 16px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 24px;
  margin-bottom: 28px;
}

.field .fieldHeader{
  display: flex;
  width: 100%;
  margin-bottom: 18px;
}

.field .fieldProp{
  margin-right: 18px;
}

.field .fieldProp .isRequired{
  margin-left: 12px;
}

.field .fieldProp .deleteIcon{
  font-size: 18.2px;
  margin-top: 3px;
}

.field .fieldProp .fieldName{
  font-size: 14px;
  font-weight: 500;
}