.calender{
  background-color: white;
  border-radius: 12px;
  margin-top: 24px;
  padding: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  max-width: 360px;
  max-height: 400px;
  margin-right: 24px;
}

.calender .react-calendar{
  border: none;
}