.displayArea {
    min-height: calc(100vh - 60px);
    background-color: #F9F9F9;
    padding: 32px 25px;
}

.displayArea .heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
}

.displayArea .allFaculty {
    display: flex;
    flex-wrap: wrap;
}