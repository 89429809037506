.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
  display: flex;
  justify-content: center;
}

.displayArea .formCreation{
  background-color: #ffffff;
  width: fit-content;
  padding: 24px 0px 0px 0px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 800px;
  position: relative;
  height: fit-content;
}

.displayArea .formCreation .formHeader {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 28px;
  padding: 0px 24px;
}

.displayArea .formCreation .addField {
  text-align: center;
  background: #e8f7f1;
  border: 1px dashed #1ab373;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 0px;
  margin: 0px 24px;
  font-size: 14px;
  font-weight: 500;
  color: #1AB373;
  cursor: pointer;
  transition: 0.25s;
  margin-bottom: 18px;
}

.displayArea .formCreation .addField:hover{
  background: #e3f3ec;
}

.displayArea .formCreation .footer{
  display: flex;
  justify-content: space-between;
  border-top: #ccc 1.25px solid;
  padding: 12px 24px;
}

.displayArea .formCreation .footer .cancelButton{
  width: 100px;
  height: 34px;
}

.displayArea .formCreation .footer .saveButton{
  width: 100px;
  height: 34px;
  background-color: #08BD80;
}




