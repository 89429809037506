.branchCard{
  position:relative;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 14px 14px;
  padding-right: 10px;
  height: fit-content;
  margin-right: 20px;
  margin-bottom: 30px;
  border-left: 7px #1AB273 solid;
  cursor: pointer;
  min-width: 298.89px;
  max-width: 298.89px;
  max-height: 92.32px;
  min-height: 92.32px;
}

.branchCard h6{
  margin-bottom: 0px;
  line-height: normal;
}

.branchCard .cardIcon{
  padding: 22px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}

.branchCard .cardIcon i{
  font-size: 20px;
  color: #FFFFFF;
}


/* CARD TEXTUAL CONTENT */
.branchCard .cardContent{
  margin-right: 12px;
  flex-grow: 1;
}

.branchCard .cardContent .courseName{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.branchCard .cardContent .courseData{
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}


/* ARROW HEAD AT LAST */
.branchCard .arrowIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.branchCard .arrowIcon:hover{
  background-color: #F9F9F9;
}


.editButton{
  position:absolute;
  bottom:5px;
  right:20px;
  color:rgba(83, 81, 81, 0.562);
}
