.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 60px 25px;
}

.displayArea h6 {
  margin-bottom: 0px;
  line-height: normal;
}

.displayArea .studentDetails {
  background-color: #ffffff;
  /* box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5); */
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-left: #1ab273 6px solid;
  /* border-radius: 4px 0px 0px 4px; */
  border-radius: 12px;
  padding-bottom: 24px;
}

.displayArea .studentDetails .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #ccc 1.5px solid;
  padding: 16px 24px;
}

.displayArea .studentDetails .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: #ccc 1.5px solid;
  padding: 16px 24px;
}

.displayArea .studentDetails .header h6 {
  font-size: 16px;
  font-weight: 600;
}

.displayArea .studentDetails .header .iconsDisplay {
  display: flex;
  align-items: center;
}

.displayArea .studentDetails .header .iconsDisplay .icons {
  padding: 3.7px 12px;
  border: #d2d6e5 1.2px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.displayArea .studentDetails .header .iconsDisplay .deleteIcon {
  padding: 7px;
  border-radius: 4px;
}

.displayArea .studentDetails .header .iconsDisplay .icons span{
  margin-right: 7px;
  padding-bottom: 3px;
}

/* .displayArea .studentDetails .header .iconsDisplay .icons img {
  margin-left: 7px;
  height: 14px;
} */

.displayArea .studentDetails .header .iconsDisplay .deleteIcon img {
  margin-left: 0px;
}

.displayArea .studentDetails .header .iconsDisplay .icons:hover {
  background-color: rgba(204, 204, 204, 0.151);
}

.displayArea .studentData {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
}

.displayArea .studentData .dataDisplay {
  display: flex;
}

.displayArea .studentData .dataDisplay .userImg {
  width: 213px;
  height: 277px;
  margin-right: 24px;
}

.displayArea .studentData .dataDisplay .defaultUserImg {
  width: 213px; 
  margin-right: 24px;
}

.displayArea .studentData .dataDisplay .studentDataDisplay {
  flex-grow: 1;
  display: flex;
  border: 1px solid #d2d6e5;
  border-right: none;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box {
  width: 33.333%;
  padding: 20px;
  border-right: 1px solid #d2d6e5;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .label {
  font-size: 12px;
  opacity: 0.7;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .value {
  font-size: 14px;
  font-weight: 650;
  margin-bottom: 16px;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .dueAmount {
  font-size: 14px;
  font-weight: 650;
  margin-bottom: 16px;
  color: red;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .feeStatus {
  padding: 4.5px 12px;
  display: flex;
  border-radius: 4px;
  width: fit-content;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: 400;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .paid{
  background: #e8f7f1;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .unpaid{
  background: #FFDADA;
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .feeStatus span{
  transform: translateY(-1.6px);
}

.displayArea .studentData .dataDisplay .studentDataDisplay .box .feeStatus img{
  margin-right: 5px;
}


.displayArea .studentDetails .extraDetails{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #d2d6e5;
  margin: 0px 24px;
  padding: 20px 24px;
  padding-bottom: 0px;
}

.displayArea .studentDetails .extraDetails div{
  margin-right: 40px;
  margin-bottom: 20px;
}

.displayArea .studentDetails .extraDetails .label{
  font-size: 12px;
  opacity: 0.7;
}

.displayArea .studentDetails .extraDetails .value{
  font-size: 14px;
  font-weight: 650;
}

.printIcon{
  position:absolute;
  padding: 3.7px 12px;
  border: rgb(9, 220, 168) 1.2px solid;
  width:80px;
  border-radius: 5px;
  left: 26px;
  top:16px;
  text-align: right;
  cursor: pointer;
  background:white;

}









