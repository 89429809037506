/* .card{
    border:1.35px solid rgba(0, 0, 0, 0.15);
    border-radius:4px;
    padding:8px 24px 8px 24px;
    margin-bottom:32px;
    margin-right:48px;
    display:flex;
    align-items:center;
    justify-content: flex-start;
    width:calc(50vw - 200px);
    color:black !important;

}

.card .nameHeading,.card .enrollementHeading{
    font-weight:bolder;
    margin:0;
    padding:0;
    margin-right:4px;
}
.card .name,.card .enrollement{
    margin:0;
    padding:0;
    margin-right:32px;
    
}
.card:hover{
    background:rgb(240, 237, 237);

} */



.studentCard {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 14px 14px;
    padding-right: 10px;
    height: fit-content;
    margin-right: 20px;
    margin-bottom: 30px;
    border-left: 7px #1AB273 solid;
    cursor: pointer;
    min-width: 350px;
    max-width: 350px;
    max-height: 150px;
    min-height: 150px;
}

.studentCard a {
    color: rgb(19, 18, 18);
}

.studentCard h5 {
    margin-bottom: 2px;
    line-height: normal;
}


.studentCard .img {
    margin-right: 12px;
}

/* CARD TEXTUAL CONTENT */
.studentCard .cardContent {
    margin-right: 12px;
    flex-grow: 1;
}

.studentCard .cardContent .courseName {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}

.studentCard .cardContent .courseData {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
}

.studentCard .arrowIcon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
}

.studentCard .arrowIcon:hover {
    background-color: #F9F9F9;
}