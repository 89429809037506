.branchCard{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  align-items: center;
  border-radius: 12px;
  padding: 14px 14px;
  padding-right: 10px;
  padding-bottom: 0px;
  height: fit-content;
  margin-bottom: 30px;
  border-left: 7px #1AB273 solid;
}

.branchCard .branchCardHeader{
  display: flex;
  align-items: center;
  position: relative;
}

.branchCard .branchCardHeader .viewAll{
  font-size: 13.4px;
  color: #290CE9;
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
}

.branchCard h6{
  margin-bottom: 0px;
  line-height: normal;
}

.branchCard .cardIcon{
  padding: 22px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}

.branchCard .cardIcon i{
  font-size: 20px;
  color: #FFFFFF;
}


/* CARD TEXTUAL CONTENT */
.branchCard .cardContent{
  margin-right: 18px;
}

.branchCard .cardContent .courseName{
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
}

.branchCard .cardContent .courseData{
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}


/* ARROW HEAD AT LAST */
.branchCard .arrowIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.branchCard .arrowIcon:hover{
  background-color: #F9F9F9;
}



.branchCard .yearsList{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-left: 12px;
}
.branchCard .yearsList .yearContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 16px;
  padding: 3.8px 3.2px 3.8px 20px;
  margin-right: 20px;
  border: #CCCCCC 1.28px solid !important;
}
.branchCard .yearsList .yearContainer span{
  margin-right: 12px;
  line-height: 100%;
  transform: translateY(-2.2px);
}










