.addOptionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: fit-content;
  padding: 20px 10px 30px 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  width: 27.5rem;
}

.addOptionBlock .header {
  margin-top: 5px;
  text-align: center;
  margin-bottom: 35px;
  font-size: 24px;
  font-weight: 500;
}

.addOptionBlock .optionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  font-size: 14.8px;
  width: 320px;
  height: 64px;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  margin-bottom: 24px;
  cursor: pointer;
  border-radius: 8px;
}

.addOptionBlock .closeIcon{
  position: absolute;
  top: 14px;
  right: 14px;
}
.addOptionBlock .closeIcon:hover{
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.5s;
}