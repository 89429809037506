.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  justify-content: center;
}
.displayArea .bulkUpload h6 {
  margin-bottom: 0px;
  line-height: 100%;
}

.displayArea .bulkUpload {
  max-width: 55%;
  min-width: 55%;
  background-color: #ffffff;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border-radius: 12px;
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* margin: auto; */
}

.displayArea .bulkUpload .formHeader {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}



/* CSS for downloading sample-file */
.displayArea .bulkUpload .downloadSample{
  width: 98%;
  margin: auto;
}
.displayArea .bulkUpload .downloadSample h6{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

/* CSS for Drag-n-drop upload section */
.displayArea .bulkUpload .upload{
  width: 98%;
  margin: auto;
  margin-bottom: 24px;
}
.displayArea .bulkUpload .upload .uploadHeader{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.displayArea .bulkUpload .dropzone {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #1AB373;
  border-radius: 8px;
  padding: 12%;
}
.displayArea .bulkUpload .dropzone .headline{
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 700;
}
.displayArea .bulkUpload .dropzone .bottomLine{
  font-size: 12.8px;
}



.displayArea .bulkUpload .uploadButton{
  width: 98%;
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
  color: #FFFFFF;
  margin: auto;
}










