.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 30px 25px;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}

.displayArea .addCourseForm {
  background-color: #ffffff;
  width: fit-content;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 700px;
}

.displayArea .addCourseForm h6 {
  margin-bottom: 0px;
}

.displayArea .addCourseForm .formHeader {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 28px;
}

.displayArea .addCourseForm .label {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 4px;
}

.displayArea .addCourseForm input{
  /* background-color: #EEF0F4; */
  padding-left: 6px;
}

.displayArea .addCourseForm .addCourseButton {
  width: 100%;
  background-color: #08bd80;
  border-radius: 8px;
  height: 36px;
  margin-top: 20px;
}
.Breadcrumb{
  align-self:center;
  justify-self: flex-start;
}