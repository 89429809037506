.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
}

.displayArea .addBatch {
  position: absolute;
  right: 35px;
  top: 25px;
  font-size: 13.4px;
  /* color: #290ce9; */
  color: #1AB373;
  text-decoration: underline;
  cursor: pointer;
}

.displayArea .deleteBatch{
  position: absolute;
  right: 180px;
  top: 25px;
  font-size: 13.4px;
  /* color: #290ce9; */
  color: #1AB373;
  text-decoration: underline;
  cursor: pointer;
}