.searchBar form{
  display: flex;
  align-items: center;
}

.searchBar input{
  min-width: 420px;
  padding-bottom: 4px;
  padding-left: 16.2px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
}