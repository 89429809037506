.doughnutGraph{
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 360px;
  max-height: 400px;
  /* margin: 24px 0; */
}

.doughnutGraph .heading{
  font-size: 16px;
  font-weight: 600;
  /* opacity: 0.7; */
}