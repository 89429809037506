.footer{
    display:flex;
    align-items:center;
    justify-content:center;
    background:#09DCA8;
    padding-top:4px;
    padding-bottom:4px;
}

.footerText{
    margin:0;
    color:white;
    font-weight:bolder;
    letter-spacing:1px;
}

.companyName{
    font-weight:800;
}