.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.displayArea .addUserForm{
  max-width: 90%;
  min-width: 90%;
  background-color: #FFFFFF;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border-radius: 12px;
  padding: 20px 10px;
}

.displayArea h6{
  margin-bottom: 0px;
  line-height: 100%;
}

.displayArea .addUserForm .userAddHeader{
  display: flex;
  align-items: center;
}

.displayArea .addUserForm .userAddHeader .arrowIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-right: 8px;
}

.displayArea .addUserForm .userAddHeader .arrowIcon:hover{
  background-color: #F9F9F9;
}

.displayArea .addUserForm .userAddHeader h6{
  font-size: 16px;
  margin-bottom: 2.9px;
}

.displayArea .addUserForm form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayArea .addUserForm .uploadButton{
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .3s;
  margin-bottom: 24px;
}

.displayArea .addUserForm .uploadButton:hover{
  border-color: #1890ff;           
}

.displayArea .addUserForm .ant-input-prefix{
  margin-right: 12px;
}

.displayArea .addUserForm .ant-input:hover{
  background-color: #EEF0F4;
}

.displayArea .addUserForm .ant-form-item-control-input-content input{
  background-color: #EEF0F4;
}

.displayArea .addUserForm .ant-form-item-control{
  border-radius: 16px !important;
}

.displayArea .addUserForm .ant-form-item-control-input{
  border-radius: 16px !important;
  min-width: 500px;
}

.displayArea .addUserForm .switchBox .ant-form-item-control-input{
  min-width: 344px !important;
}

.displayArea .addUserForm .ant-form-item-control-input-content{
  border-radius: 16px !important;
}

.displayArea .addUserForm .login-form-button{
  width: 100%;
  background-color: #08BD80;
  border-radius: 8px;
  height: 36px;
  /* padding: 3px 0px 7px 0px; */
}

.displayArea .addUserForm .ant-form-item-explain-error{
  font-size: 11px;
}






