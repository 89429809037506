.batch h6{
  margin-bottom: 0px;
  line-height: normal;
}
.batch{
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.batch .yearsHeading{
  font-size: 14.8px;
  margin-bottom: 20px;
}
.batch .displayYears{
  flex-grow: 2;
  display: flex;
  flex-wrap: wrap;
}
.batch .year{
  padding: 12px 22px;
  margin-right: 22px;
  margin-bottom: 16px;
  border: rgb(167, 167, 167) 1px solid;
  border-radius: 2px;
}

.batch .year .yearLabel{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 7px;
  display: flex;
}
.batch .year .yearLabel .label{
  opacity: 0.6;
  margin-right: 22px;
  font-weight: 500;
}

.batch .year .feeLabel{
  font-size: 14px;
  font-weight: 600;
  display: flex;
}
.batch .year .feeLabel .label{
  width: 107.6px;
  opacity: 0.6;
  margin-right: 22px;
  font-weight: 500;
}

.yearCard{
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  height: fit-content;
  margin: 16px 8px;
  padding: 16px 24px;
}

.yearCard h5{
  font-size: 14px;
text-decoration: underline;
}


.feeDetails{
  border: rgba(204, 204, 204, 0.3) 1.4px solid;
  border-radius: 6px;
}
.header {
  display: flex;
  padding: 8px;
  margin-bottom: 8px;
  border-bottom: rgba(204, 204, 204, 0.3) 1.4px solid;
}
.header h6 {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
}

.feeDetails .header h6:nth-child(1){
  min-width: 24%;
  max-width: 24%;
  margin-right: 18px;
}
.feeDetails .header h6:nth-child(2){
  min-width: 18%;
  max-width: 18%;
  margin-right: 18px;
}
.feeDetails .header h6:nth-child(4){
  min-width: 18%;
  max-width: 18%;
}

.feeDetails .footer .saveForm{
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
}
.feeDetails .statusBody .feeRow {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.feeDetails .statusBody .feeRow .feeType {
  min-width: 24%;
  max-width: 24%;
  overflow-x: hidden;
  padding: 4px 8px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

.feeDetails .statusBody .feeRow .feeAmount {
  min-width: 18%;
  max-width: 18%;
  overflow-x: hidden;
  padding: 4px 8px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

 .feeNote {
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 .check{
  min-width: 14%;
  max-width: 14%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.feeNoteNote {
  min-width: 80%;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
}

.editbtn{
  display: block;
  width: 29px;
  height: 29px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.editbtn:hover{
  background-color: #ccc;
  cursor: pointer;
}