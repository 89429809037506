.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea .heading{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
}

.displayArea .allBranches{
  display: flex;
  flex-wrap: wrap;
}

.allBranches .addBranch{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 298.89px;
  height: 92.33px;
  border-radius: 12px;
  border: 2px dashed #1AB373;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 30px;
}


.allBranches .addBranch span{
  color: #1AB373;
  font-weight: 600;
  font-size: 16px;
  margin-right: 12px;
}

.allBranches .addBranch img{
  height: 18px;
  transform: translateY(2px);
}