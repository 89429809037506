.overlayBackground {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(249, 249, 249, 0.75);
  backdrop-filter: blur("2px");
  z-index: 1000;
  display: flex;
}
