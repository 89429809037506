/* CSS for receipt-table */
.receiptTableBody {
  margin-top: 30px 20px;
  background-color: #ffffff;
  padding: 20px 0px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
}

.receiptTableBody .tableTop {
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.receiptTableBody .tableBottom {
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  border-top: 1px solid #d9d9d9;
  justify-content: space-between;
  align-items: center;
}

.receiptTableBody .tableTop .tableTopHeading {
  font-size: 16px;
  margin-right: 20px;
}

.receiptTableBody .tableTop .visOption {
  margin: 4px 0px !important;
}

/* CSS for receipt-search-bar */
.receiptTableBody .searchForm {
  background-color: #ffffff;
  margin-bottom: 20px;
  display: inline;
  align-items: center;
  padding: 4.55px 10px;
  border: #ccc 1.2px solid;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.receiptTableBody .searchForm img{
  width: 15px;
  margin-bottom: 4px;
}
.receiptTableBody .searchForm input {
  padding-bottom: 2px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  font-weight: 400;
  border: none;
  outline: none;
}
