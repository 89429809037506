*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  padding-top: 60px;
  font-family: "Be Vietnam", sans-serif;
  /* background: url('./assets/background.png'); */
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Roboto", sans-serif; */
}

.ant-checkbox-wrapper{
  margin: 4px 0px !important;
  margin-left: 0px !important;
}
.ant-form-item-explain-error{
  font-size: 11.8px !important;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: red !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

.back-arrow {
  display: block;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  font-size: 1rem;
  color: #8A8A8A;
  padding-bottom: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-arrow:hover {
  background-color: #dfdfdf;
}