.dashboardCard h6{
  margin-bottom: 0px;
  line-height: normal;
}

.dashboardCard{
  background-color: white;
  min-width: 400px;
  max-width: 400px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 22px 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.dashboardCard img{
  margin-right: 22px;
  height: 52px;
  width: 52px;
}

.dashboardCard h6{
  font-size: 13px;
  font-weight: 400;
  opacity: 0.7;
  margin-bottom: 3px !important;
}

.dashboardCard .countUp{
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}
.cardIcon{
  padding: 22px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}

.cardIcon i{
  font-size: 20px;
  color: #FFFFFF;
}

.dashboardElements .calender{
  margin:0;
}