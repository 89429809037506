.displayArea {
  /* min-height: calc(100vh - 60px); */
  background-color: #f9f9f9;
  padding: 30px 25px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.displayArea .addFeeForm {
  background-color: #ffffff;
  width: fit-content;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 950px;
}

.displayArea .addFeeForm h6 {
  margin-bottom: 0px;
}

.displayArea .addFeeForm .formHeader {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 28px;
}

.displayArea .addFeeForm .label {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 4px;
}

.displayArea .addFeeForm input{
  /* background-color: #EEF0F4; */
  padding-left: 6px;
}

.displayArea .addFeeForm .breakdown{
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 6px;
  font-weight: 400;
}

.displayArea .addFeeForm .feeDetails{
  border: rgba(204, 204, 204, 0.555) 1.8px solid;
  border-radius: 6px;
}
.displayArea .addFeeForm .feeDetails .header {
  display: flex;
  padding: 19px 24px;
  border-bottom: rgba(204, 204, 204, 0.555) 1.8px solid;
}
.displayArea .addFeeForm .feeDetails .header h6 {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
}

.displayArea .addFeeForm .feeDetails .header h6:nth-child(1){
  min-width: 24%;
  max-width: 24%;
  margin-right: 18px;
}
.displayArea .addFeeForm .feeDetails .header h6:nth-child(2){
  min-width: 18%;
  max-width: 18%;
  margin-right: 18px;
}
.displayArea .addFeeForm .feeDetails .header h6:nth-child(4){
  min-width: 16%;
  max-width: 16%;
}

.displayArea .addFeeForm .feeDetails .footer .saveForm{
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
}
.displayArea .addFeeForm .feeDetails .statusBody .feeRow {
  display: flex;
  align-items: center;
  padding: 12px 24px;
}

.displayArea .addFeeForm .feeDetails .statusBody .feeRow .feeType {
  min-width: 24%;
  max-width: 24%;
  overflow-x: hidden;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

.displayArea .addFeeForm .feeDetails .statusBody .feeRow .feeAmount {
  min-width: 18%;
  max-width: 18%;
  overflow-x: hidden;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

.displayArea .addFeeForm .feeDetails .statusBody .feeRow .feeNote {
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.displayArea .addFeeForm .feeDetails .statusBody .feeRow .check{
  min-width: 14%;
  max-width: 14%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.displayArea .addFeeForm .feeDetails .statusBody .feeRow .feeNote .feeNoteNote {
  min-width: 100%;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
}

.displayArea .addFeeForm .feeDetails .statusBody .addFeeType{
  text-align: center;
  background: #e8f7f1;
  border: 1px dashed #1ab373;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6.8px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #1AB373;
  cursor: pointer;
  margin: 12px 24px;
  transition: 0.25s;
  margin-top: 24px;
}
.displayArea .addFeeForm .feeDetails .statusBody .addFee:hover{
  background: #e3f3ec;
}

.displayArea .addFeeForm .addFeeButton {
  width: 100%;
  background-color: #08bd80;
  border-radius: 8px;
  height: 36px;
  margin-top: 20px;
}







