.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  justify-content: center;
}

.displayArea .addStudentForm {
  max-width: 74%;
  min-width: 74%;
  background-color: #FFFFFF;
  box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border-radius: 12px;
  padding: 24px;
  margin: auto;
}

.displayArea .addStudentForm form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayArea .addStudentForm h6{
  margin-bottom: 0px;
  line-height: 100%;
}

.displayArea .addStudentForm .formHeader {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.displayArea .addStudentForm .uploadButton{
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .3s;
  margin-bottom: 24px;
}
.displayArea .addStudentForm .uploadButton:hover{
  border-color: #1890ff;           
}

.displayArea .addStudentForm .itemLabel{
  width: 500px;
  margin-bottom: 8px;
  font-size: 14px;
  opacity: 0.7;
  padding-left: 7px;
}

.agentCheckbox{
  font-size: 12px;
  transform: translateY(-1px);
}

.displayArea .addStudentForm .ant-input-prefix{
  margin-right: 12px;
}
/* .displayArea .addStudentForm .ant-input:hover{
  background-color: #EEF0F4;
}
.displayArea .addStudentForm .ant-form-item-control-input-content input{
  background-color: #EEF0F4;
} */
.displayArea .addStudentForm .ant-form-item-control{
  border-radius: 16px !important;
}
.displayArea .addStudentForm .ant-form-item-control-input{
  border-radius: 16px !important;
  min-width: 500px;
}
/* .displayArea .addStudentForm .ant-input-number-input-wrap{
  border-radius: 16px !important;
  min-width: 500px !important;
}
.displayArea .addStudentForm .ant-input-number-input{
  border-radius: 16px !important;
  min-width: 500px !important;
} */
.displayArea .addStudentForm .ant-form-item-control-input-content{
  border-radius: 16px !important;
  background-color: #FFFFFF !important;
}
.displayArea .addStudentForm .ant-form-item-explain-error{
  font-size: 11px;
}


.displayArea .addStudentForm .add-student-form-button{
  width: 100%;
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
}