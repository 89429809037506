.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .receiptHeader{
  background-color: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 803px !important;
  max-width: 803px !important;
  margin: auto;
  margin-top:40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.displayArea .receiptHeader .rightDiv{
  display: flex; 
  align-items: center;
}
.displayArea .receiptHeader .rightDiv h6{
  line-height: 100%; 
  font-size: 12px;
  transform: translateY(-0.8px);
} */


/* PDF STYLES */
.receiptContainer {
  background-color: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 1cm;
  min-width: 803px !important;
  max-width: 803px !important;
  margin: auto;
  margin-top: 20px;
}

/* @font-face {
  font-family: "Be Vietnam", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@100;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
} */

.receiptContainer h6 {
  margin-bottom: 0px;
  line-height: normal;
}
.receiptContainer h5 {
  margin-bottom: 0px;
  line-height: normal;
}

/* header */
.receiptContainer .pdfHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.receiptContainer .pdfHeader img {
  max-height: 85px;
  max-width: 85px;
}
.receiptContainer .pdfHeader .collegeDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.receiptContainer .pdfHeader .collegeDetails h5 {
  font-size: 2em !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
}
.receiptContainer .pdfHeader .collegeDetails .collegeName {
  font-size: 18px;
  letter-spacing: 0.02em;
  font-weight: 600;
}
.receiptContainer .pdfHeader .collegeDetails .location {
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: 600;
  opacity: 0.6;
}
.receiptContainer .pdfHeader .scanner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.receiptContainer .pdfHeader canvas {
  height: 74px !important;
  width: 74px !important;
  margin-bottom: 4px;
}

/* student details */
.receiptContainer .studentDetailsSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.receiptContainer .studentDetailsSection .details {
  min-width: 55%;
}
.receiptContainer .studentDetailsSection .data {
  display: flex;
  width: 100%;
  /* margin-bottom: 3px; */
}
.receiptContainer .studentDetailsSection .data .label {
  min-width: 38%;
  max-width: 38%;
  margin-right: 16px;
  font-size: 14.8px;
  font-weight: 550;
}
.receiptContainer .studentDetailsSection .data .value {
  font-size: 14.8px;
  font-weight: 400;
}

/* fee table */
.receiptContainer .feeTable {
  background: #ffffff;
  border: 1px solid #242e42;
  margin-bottom: 5px;
}
.receiptContainer .feeTable .tableHeader {
  display: flex;
  background: #fcfcfc;
  padding: 8px 0px;
}
.receiptContainer .feeTable .tableRow {
  display: flex;
  padding: 2px 0px;
}
.receiptContainer .feeTable .tableFooter{
  display: flex;
  justify-content: flex-end;
  padding: 8px 0px;
  background: #fcfcfc;
}
.receiptContainer .feeTable .tableFooter h6{
  font-size: 14px;
  margin-right: 30px;
}
.receiptContainer .feeTable .tableRow h6 {
  font-size: 14px;
}
.receiptContainer .feeTable .tableHeader h6 {
  font-size: 14px;
}
.receiptContainer .feeTable .tableHeader .sno {
  min-width: 12%;
  text-align: center;
}
.receiptContainer .feeTable .tableHeader .feeType {
  min-width: 35%;
  text-align: center;
}

.recieptId{
  min-width: 15%;
  text-align: center;
}

.receiptContainer .feeTable .tableHeader .amount {
  min-width: 25%;
  text-align: center;
}
.receiptContainer .feeTable .tableHeader .note {
  flex-grow: 1;
  text-align: center;
}
.receiptContainer .feeTable .tableRow .sno {
  min-width: 12%;
  text-align: center;
}
.receiptContainer .feeTable .tableRow .feeType {
  min-width: 35%;
  text-align: center;
}
.receiptContainer .feeTable .tableRow .amount {
  min-width: 25%;
  text-align: center;
}
.receiptContainer .feeTable .tableRow .note {
  flex-grow: 1;
  text-align: center;
}


/* pdf footer */
.receiptContainer .pdfFooter{
  display: flex;
  justify-content: space-between;
  position: relative;
}
.receiptContainer .pdfFooter .paymentDetails .paymentData{
  display: flex;
}
.receiptContainer .pdfFooter .paymentDetails .paymentData h6{
  font-size: 14px;
  opacity: 1;
  /* margin-bottom: 20px; */
}
.receiptContainer .pdfFooter .paymentDetails .paymentData .label{
  margin-right: 14px;
  opacity: 0.7;
}
.receiptContainer .pdfFooter img{
  max-height: 120px;
  max-width: 120px;
}

.stampArea{
  padding-top: 5rem;
}