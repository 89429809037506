.userDetail h6{
  margin-bottom: 0px;
  font-weight: 600;
  line-height: normal;
}

.userDetail{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.userDetail .textDetails{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.userDetail .userName{
  font-size: 14px;
}
.userDetail .userStatus{
  color: #D2D6E5;
  font-size: 10px;
  width: fit-content;
}

.userDetail img{
  margin-left: 16px;
  border-radius: 50%;
  height: 42px;
  width: 42px;
}