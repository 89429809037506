.adminDashboard h6{
  margin-bottom: 0px;
  line-height: normal;
}
.adminDashboard{
  padding: 32px;
  background-color: #F9F9F9;
  min-height: calc(100vh - 60px);
}

.adminDashboard .heading{
  font-size: 16.9px;
  font-weight: 600;
  margin-bottom: 24px;
}

.adminDashboard .dashboardDisplay{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.adminDashboard .dashboardElements{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}