.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.displayArea .addBranchForm {
  background-color: #ffffff;
  width: fit-content;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 700px;
}

.displayArea .addBranchForm h6 {
  margin-bottom: 0px;
}

.displayArea .addBranchForm .formHeader {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 28px;
}

.displayArea .addBranchForm .label {
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 4px;
}

.displayArea .addBranchForm input{
  /* background-color: #EEF0F4; */
  padding-left: 6px;
}

/* .displayArea .addBranchForm input:hover{
  background-color: #EEF0F4;
} */

/* .displayArea .addBranchForm .ant-select-selector{
  background-color: #EEF0F4 !important;
} */

.displayArea .addBranchForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  /* background-color: #EEF0F4 !important; */
  padding-left: 6px;
}

/* If input background color is needed to be white */
/* .displayArea .ant-input:hover{
  background-color: #FFFFFF;
}

.displayArea .ant-form-item-control-input-content input{
  background-color: #FFFFFF;
} */

.displayArea .addBranchForm .addCourseButton {
  width: 100%;
  background-color: #08bd80;
  border-radius: 8px;
  height: 36px;
  margin-top: 20px;
}










