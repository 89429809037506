.sideWindow{
  border: #ccc 1px solid;
  width: 288px;
  position: fixed;
  right: 0;
  bottom: 0;
  transition: min-height 1s ease;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 7px 7px 0px 0px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.sideWindow h6{
  margin-bottom: 0px;
  line-height: normal;
}


.sideWindow .list{
  min-height: 450px;
  max-height: 450px;
}
.sideWindow .listHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 14px;
  border-bottom: #ccc 1px solid;
}
.sideWindow .listHeader h6{
  font-size: 14px;
  font-weight: 600;
}
.sideWindow .listHeader .arrow{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
}
.sideWindow .listHeader .arrow:hover{
  background-color: #cccccc;
}



.sideWindow .list .studentBlock{
  display: flex;
  align-items: center;
  padding: 7.6px;
  border-bottom: #ccc 0.5px solid;
}
.sideWindow .list .studentBlock img{
  margin-right: 12px;
}
.sideWindow .list .studentBlock .studentData{
  flex-grow: 1;
}
.sideWindow .list .studentBlock .studentData .studentName{
  font-size: 14px;
  font-weight: 500;
}
.sideWindow .list .studentBlock .studentData .studentId{
  font-size: 11.4px;
  font-weight: 500;
  opacity: 0.7;
}
.sideWindow .list .studentBlock .deleteDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 50%;
}
.sideWindow .list .studentBlock .deleteDiv:hover{
  background-color: #fcf8f8;
}

