.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
}

.displayArea .studentsListTableBody{
  background-color: #FFFFFF;
  padding-top: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  margin-bottom: 1px;
}

.displayArea .studentsListTableBody .tableTop{
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .tableBody{
  padding-top: 14px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.displayArea .tableBody .header{
  display: flex;
  padding: 5px 0px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 18px;
}
.displayArea .tableBody .row{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.8px 24px 7px 24px;
  border-top: 2.8px #f9f9f9 solid;
}
.displayArea .pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 15px 30px;
  border-radius: 0px 0px 10px 10px;
  border-top: #f9f9f9 2.8px solid;
}