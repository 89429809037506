.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea h6{
  margin-bottom: 0px;
  line-height: normal;
}

.displayArea .promotionListTableBody {
  max-width: 994px;
}


/* CSS for receipt-search-bar */
.promotionListTableBody .searchForm {
  background-color: #ffffff;
  margin-bottom: 20px;
  display: inline;
  align-items: center;
  padding: 4.55px 10px;
  border: #ccc 1.2px solid;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.promotionListTableBody .searchForm img{
  width: 15px;
  margin-bottom: 4px;
}
.promotionListTableBody .searchForm input {
  padding-bottom: 2px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  font-weight: 400;
  border: none;
  outline: none;
}



/* CSS for table */
.displayArea .promotionListTableBody{
  background-color: #FFFFFF;
  padding-top: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
}

.displayArea .promotionListTableBody .tableTop{
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .promotionListTableBody .tableTop .tableTopHeading{
  font-size: 16px;
  margin-right: 20px;
}

.displayArea .promotionListTableBody .tableTop .visOption{
  margin: 4px 0px !important;
}


.displayArea .promoteButton{
  margin: 24px 0px 30px 0px;
  width: 100%;
  background-color: #08BD80;
  border-radius: 2px;
  height: 36px;
  max-width: 994px;
}