.navBar{
  position: fixed;
  top: 0;
  box-shadow: inset 0px -1px 0px #d1d1d1;
  /* filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.03)); */
  height: 60px;
  padding-left: 10%;
  background-color: #ffffff;
  z-index: 99;
  width: 100%;
  display: flex;
  align-items: center;
}

.navBar i{
  line-height: 100%;
  color: black;
  margin-right: 20px;
  cursor: pointer;
  transform: translateY(-2px);
}

.navBar .logoName{
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
}

/* DASHBOARD NAVBAR STYLES */

.dashboardNav{
  width: calc(100vw - 220px);
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}

















