.ViewAgent{
    background-color: #F9F9F9;
    padding: 32px 25px;
}

.Table{
    background-color: #FFFFFF;
    box-shadow: 9px 9px 16px rgba(163, 177, 198, 0.6),
      -9px -9px 16px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border-radius: 12px;
    padding: 20px 40px;
    padding-top:40px;
}