.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
}

.displayArea h6{
  margin-bottom: 0px;
  line-height: normal;
}



/* CSS for search-bar */
.displayArea .studentsListSearchForm{
  width: 100%;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.displayArea .studentsListSearchForm input{
  width: 100%;
  padding-bottom: 2px;
  padding-left: 16.2px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
}


/* CSS for table */
.displayArea .studentsListTableBody{
  background-color: #FFFFFF;
  padding-top: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
}

.displayArea .studentsListTableBody .tableTop{
  padding: 0px 20px;
  padding-left: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .studentsListTableBody .tableTop .tableTopHeading{
  font-size: 16px;
  margin-right: 20px;
}

.displayArea .studentsListTableBody .tableTop .visOption{
  margin: 4px 0px !important;
}