.addFeeForm .formRow{
  display: flex;
  padding: 12px 24px;
}

.addFeeForm .saveFee{
  text-align: center;
  background: #e8f7f1;
  border: 1px dashed #1ab373;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6.8px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #1AB373;
  cursor: pointer;
  margin: 12px 24px;
  transition: 0.25s;
}

.addFeeForm .saveButton{
  width: 92.4%;
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
}

.addFeeForm .ant-form-item-explain-error{
  font-size: 10px !important;
}