.rdt_TableRow:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.rdt_TableRow {
  margin: 0.375rem 0;
  margin-bottom: 0px;
  border: none !important;
}
.rdt_TableHeadRow .rdt_TableCol div{
  font-weight: 650 !important;
  font-size: 14px;
}
/* .rdt_TableRow > .rdt_TableCell:first-child::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 100%;
  top: 0;
  left: 0;

  border-top-left-radius: 8px;
  border-left: 1.35px solid rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 8px;
} */
.rdt_TableCell {
  user-select: none;
  min-width: fit-content !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.rdt_TableHeader {
  display: none !important;
}
.rdt_TableCell:last-child {
  border-right: 1.35px solid rgba(0, 0, 0, 0.15);
  border-top: 1.35px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1.35px solid rgba(0, 0, 0, 0.15);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 10px;
}
.rdt_TableCell:first-child {
  border-left: 1.35px solid rgba(0, 0, 0, 0.15);
  border-top: 1.35px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1.35px solid rgba(0, 0, 0, 0.15);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-left: 10px;
}
.rdt_TableCol_Sortable span{
  opacity: 1;
}





































/* For colored left-tip of rows */
/* .colorTableRows .rdt_TableRow:nth-child(1) .rdt_TableCell:first-child::before {
  background-color: #1ab373;
}
.colorTableRows .rdt_TableRow:nth-child(2) .rdt_TableCell:first-child::before {
  background-color: #f1994a;
}
.colorTableRows .rdt_TableRow:nth-child(3) .rdt_TableCell:first-child::before {
  background-color: #7887ff;
}
.colorTableRows .rdt_TableRow:nth-child(4) .rdt_TableCell:first-child::before {
  background-color: #958a5f;
}
.colorTableRows .rdt_TableRow:nth-child(5) .rdt_TableCell:first-child::before {
  background-color: #1ab373;
}
.colorTableRows .rdt_TableRow:nth-child(6) .rdt_TableCell:first-child::before {
  background-color: #f1994a;
}
.colorTableRows .rdt_TableRow:nth-child(7) .rdt_TableCell:first-child::before {
  background-color: #7887ff;
}
.colorTableRows .rdt_TableRow:nth-child(8) .rdt_TableCell:first-child::before {
  background-color: #958a5f;
} */