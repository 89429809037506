/* For courses-list page */
.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea .allCourses{
  display: flex;
  flex-wrap: wrap;
}


/* For branches-list page */
.displayArea{
  min-height: calc(100vh - 60px);
  background-color: #F9F9F9;
  padding: 32px 25px;
}

.displayArea .heading{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
}

.displayArea .viewAllCourseButton{
  text-align: center;
  line-height: 90%;
  font-size: 14px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  padding: 12px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 2px dashed #1AB373;
  cursor: pointer;
  color: #1AB373;
}

.displayArea .viewAllCourseButton:hover{
  background-color: #F9F9F9;
  transition: 0.5s;
}

.displayArea .allBranches{
  display: flex;
  flex-direction: column;
}
