.SideBar .sidebarOption {
  display: flex;
  align-items: center;
  padding: 7.4px 3px;
  position: relative;
  padding-left: 22px;
}

.SideBar .sidebarOption h6{
  margin-bottom: 0px;
}

.SideBar .sidebarOption img {
  margin-right: 18px;
  width: 20px;
}

.SideBar .sidebarOption .heading {
  font-size: 16px;
}

.SideBar .sidebarOption .arrow {
  position: absolute;
  right: 10%;
  cursor: pointer;
}

.SideBar .sidebarOption:hover{
  color: #1ab273;
  cursor: pointer;
}

.SideBar .subOptions{
  padding: 9px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.SideBar .subOptionsContainer a{
  text-decoration: none;
  color: none;
}

.SideBar .subOptions h6{
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.SideBar .subOptions:hover{
  background-color: #E8F7F1;
  color: #1AB273;
  border-radius: 4px;
}

.SideBar .sideoptionLink{
  color: black;
  text-decoration: none;
}

.SideBar .activeIcon{
  display: none;
}

.SideBar .activeSideoptionLink .heading{
  color:#1AB273;
}

.SideBar .activeSideoptionLink .icon{
  display: none;
}

.SideBar .activeSubOption .subOptions h6{
  color: #1AB273;
}

.SideBar .activeSideoptionLink .activeIcon{
  display: block;
}



