.facultyCard {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 14px 14px;
    padding-right: 10px;
    height: fit-content;
    margin-right: 20px;
    margin-bottom: 30px;
    border-left: 7px #1AB273 solid;
    cursor: pointer;
    min-width: 350px;
    max-width: 350px;
    max-height: 150px;
    min-height: 150px;
}

.facultyCard a {
    color: rgb(19, 18, 18);
}

.facultyCard h5 {
    margin-bottom: 2px;
    line-height: normal;
}


.facultyCard .img {
    margin-right: 12px;
}

/* CARD TEXTUAL CONTENT */
.facultyCard .cardContent {
    margin-right: 12px;
    flex-grow: 1;
}

.facultyCard .cardContent .courseName {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}

.facultyCard .cardContent .courseData {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
}

.facultyCard .arrowIcon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
}

.facultyCard .arrowIcon:hover {
    background-color: #F9F9F9;
}