.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #f9f9f9;
  padding: 32px 25px;
  display: flex;
  justify-content: space-between;
}

.displayArea h6 {
  margin-bottom: 0px;
  line-height: 100%;
}

/* FEE STATUS CSS */
.displayArea .leftContainer{
  flex-grow: 1;
  margin-right: 30px;
}

.displayArea .feeSpanSelection{
  background: #ffffff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
}
.displayArea .feeSpanSelection h6{
  margin-bottom: 8px;
  font-size: 12px;
}

.displayArea .feeDetails {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: fit-content;
}

.displayArea .feeDetails .header {
  display: flex;
  padding: 19px 24px;
  border-bottom: rgba(204, 204, 204, 0.555) 1.8px solid;
}

.displayArea .feeDetails .footer {
  display: flex;
  padding: 14px 24px;
  border-top: rgba(204, 204, 204, 0.555) 1.8px solid;
  align-items: center;
  justify-content: space-between;
}

.displayArea .feeDetails .footer .saveForm{
  background-color: #08BD80;
  border-radius: 4px;
  height: 36px;
}

.displayArea .feeDetails .header h6 {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
}

.displayArea .feeDetails .statusBody{
  min-height: 270px;
}

.displayArea .feeDetails .statusBody .feeRow {
  display: flex;
  align-items: center;
  padding: 12px 24px;
}

.displayArea .feeDetails .statusBody .feeRow .feeType {
  min-width: 24%;
  max-width: 24%;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

.displayArea .feeDetails .statusBody .feeRow .feeAmount {
  min-width: 26%;
  max-width: 26%;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
  margin-right: 18px;
}

.displayArea .feeDetails .statusBody .feeRow .feeNote {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayArea .feeDetails .statusBody .feeRow .feeNote .feeNoteNote {
  min-width: 88%;
  padding: 9px 14px;
  background-color: #f3f4f8;
  border-radius: 6px;
  min-height: 40px !important;
}

.displayArea .feeDetails .statusBody .addFee{
  text-align: center;
  background: #e8f7f1;
  border: 1px dashed #1ab373;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6.8px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #1AB373;
  cursor: pointer;
  margin: 12px 24px;
  transition: 0.25s;
  margin-top: 24px;
}

.displayArea .feeDetails .statusBody .addFee:hover{
  background: #e3f3ec;
}





/* PROFILE-DETAILS CSS */
.displayArea .profileDetails {
  min-width: 300px;
  max-width: 300px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  height: fit-content;
}

.displayArea .profileDetails .userImage {
  max-width: 102px;
  margin-bottom: 40px;
}

.displayArea .profileDetails .userData {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;
}

.displayArea .profileDetails .userData .label {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  margin-right: 20px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  flex-wrap: wrap;
  color: #242e42;
  word-wrap: break-word;
  display: inline-block;
}

.displayArea .profileDetails .userData .data {
  font-weight: 500;
  letter-spacing: 0.06em;
  max-width: 54%;
  word-break: normal;
  word-wrap: break-word;
  display: inline-block;
}

.displayArea .profileDetails .userData .pendingFee {
  font-weight: 500;
  letter-spacing: 0.06em;
  max-width: 54%;
  word-break: normal;
  word-wrap: break-word;
  display: inline-block;
  color: rgb(255, 50, 50);
}