.lineGraph{
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-height: 400px;
  flex-grow: 1;
  margin: 0 20px;
}

.lineGraph .heading{
  font-size: 16px;
  font-weight: 600;
  /* opacity: 0.7; */
}

.lineGraph canvas{
  height: 300px !important;
  width: 100% !important;
}