.displayArea {
  min-height: calc(100vh - 60px);
  background-color: #ffffff;
  
  /* flex-direction:column; */
}
.cardArea{
  padding: 30px 25px;
  display: flex;
  flex-wrap:wrap;
  align-items: flex-start;
  justify-content: center;
  color:black !important;
}